"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = [
    {
        key: 'unique',
        ignore: true,
    },
    {
        key: 'validateOn',
        ignore: true,
    },
];
