"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = [
    {
        key: 'multiple',
        ignore: true
    },
    {
        key: 'allowCalculateOverride',
        ignore: true
    },
    {
        key: 'defaultValue',
        ignore: true
    },
];
