"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateTime = exports.validateTimeSync = void 0;
const util_1 = require("../util");
const error_1 = require("../../../error");
const date_1 = require("../../../utils/date");
const isValidatableTimeComponent = (comp) => {
    return comp && comp.type === 'time';
};
const validateTimeSync = (context) => {
    const { component, value } = context;
    if (!isValidatableTimeComponent(component)) {
        return null;
    }
    try {
        if (!value || (0, util_1.isEmpty)(component, value))
            return null;
        const isValid = typeof value === 'string' ?
            (0, date_1.dayjs)(value, component.format).isValid() : (0, date_1.dayjs)(String(value), component.format).isValid();
        return isValid ? null : new error_1.FieldError('time', context);
    }
    catch (err) {
        throw new error_1.ValidatorError(`Could not validate time component ${component.key} with value ${value}`);
    }
};
exports.validateTimeSync = validateTimeSync;
const validateTime = (context) => __awaiter(void 0, void 0, void 0, function* () {
    return (0, exports.validateTimeSync)(context);
});
exports.validateTime = validateTime;
