"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.processOneSync = exports.processOne = void 0;
const types_1 = require("../types");
function processOne({ component, components, path, data, row, process, instance, processors, index, scope }) {
    return __awaiter(this, void 0, void 0, function* () {
        for (const processor of processors) {
            yield processor({
                component,
                components,
                instance,
                data,
                path,
                scope,
                index,
                row,
                process,
                processor: types_1.ProcessorType.Custom
            });
        }
    });
}
exports.processOne = processOne;
function processOneSync({ component, components, path, data, row, process, instance, processors, index, scope }) {
    processors.forEach((processor) => processor({
        component,
        components,
        instance,
        data,
        path,
        scope,
        index,
        row,
        process,
        processor: types_1.ProcessorType.Custom
    }));
}
exports.processOneSync = processOneSync;
