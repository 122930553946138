"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.eachComponentAsync = exports.eachComponent = exports.eachComponentData = exports.eachComponentDataAsync = exports.uniqueName = exports.guid = exports.flattenComponents = void 0;
const lodash_1 = require("lodash");
const Evaluator_1 = require("./Evaluator");
/**
 * Flatten the form components for data manipulation.
 *
 * @param {Object} components
 *   The components to iterate.
 * @param {Boolean} includeAll
 *   Whether or not to include layout components.
 *
 * @returns {Object}
 *   The flattened components map.
 */
function flattenComponents(components, includeAll) {
    const flattened = {};
    eachComponent(components, (component, path) => {
        flattened[path] = component;
    }, includeAll);
    return flattened;
}
exports.flattenComponents = flattenComponents;
function guid() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
        const r = (Math.random() * 16) | 0;
        const v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}
exports.guid = guid;
/**
 * Make a filename guaranteed to be unique.
 * @param name
 * @param template
 * @param evalContext
 * @returns {string}
 */
function uniqueName(name, template, evalContext) {
    template = template || "{{fileName}}-{{guid}}";
    //include guid in template anyway, to prevent overwriting issue if filename matches existing file
    if (!template.includes("{{guid}}")) {
        template = `${template}-{{guid}}`;
    }
    const parts = name.split(".");
    let fileName = parts.slice(0, parts.length - 1).join(".");
    const extension = parts.length > 1 ? `.${(0, lodash_1.last)(parts)}` : "";
    //allow only 100 characters from original name to avoid issues with filename length restrictions
    fileName = fileName.substr(0, 100);
    evalContext = Object.assign(evalContext || {}, {
        fileName,
        guid: guid(),
    });
    //only letters, numbers, dots, dashes, underscores and spaces are allowed. Anything else will be replaced with dash
    const uniqueName = `${Evaluator_1.Evaluator.interpolate(template, evalContext)}${extension}`.replace(/[^0-9a-zA-Z.\-_ ]/g, "-");
    return uniqueName;
}
exports.uniqueName = uniqueName;
// Async each component data.
const eachComponentDataAsync = (components, data, row, fn, path = "", index) => __awaiter(void 0, void 0, void 0, function* () {
    if (!components || !data) {
        return;
    }
    row = row || data;
    return yield eachComponentAsync(components, (component, compPath, componentComponents) => __awaiter(void 0, void 0, void 0, function* () {
        if ((yield fn(component, data, row, compPath, componentComponents, index)) === true) {
            return true;
        }
        if (TREE_COMPONENTS.includes(component.type) || component.tree) {
            row = (0, lodash_1.get)(data, compPath, data);
            if (Array.isArray(row)) {
                for (let i = 0; i < row.length; i++) {
                    yield (0, exports.eachComponentDataAsync)(component.components, data, row[i], fn, `${compPath}[${i}]`, i);
                }
                return true;
            }
            else if ((0, lodash_1.isEmpty)(row)) {
                // Tree components may submit empty objects; since we've already evaluated the parent tree/layout component, we won't worry about constituent elements
                return true;
            }
            yield (0, exports.eachComponentDataAsync)(component.components, data, row, fn, compPath);
            return true;
        }
        else {
            return false;
        }
    }), true, path);
});
exports.eachComponentDataAsync = eachComponentDataAsync;
const TREE_COMPONENTS = [
    "datagrid",
    "editgrid",
    "container",
    "form",
    "dynamicWizard",
];
const eachComponentData = (components, data, row, fn, path = "", index) => {
    if (!components || !data) {
        return;
    }
    return eachComponent(components, (component, compPath, componentComponents) => {
        row = row || data;
        if (fn(component, data, row, compPath, componentComponents, index) === true) {
            return true;
        }
        if (TREE_COMPONENTS.includes(component.type) || component.tree) {
            row = (0, lodash_1.get)(data, compPath, data);
            if (Array.isArray(row)) {
                for (let i = 0; i < row.length; i++) {
                    (0, exports.eachComponentData)(component.components, data, row[i], fn, `${compPath}[${i}]`, i);
                }
                return true;
            }
            else if ((0, lodash_1.isEmpty)(row)) {
                // Tree components may submit empty objects; since we've already evaluated the parent tree/layout component, we won't worry about constituent elements
                return true;
            }
            (0, exports.eachComponentData)(component.components, data, row, fn, compPath, index);
            return true;
        }
        else {
            return false;
        }
    }, true, path);
};
exports.eachComponentData = eachComponentData;
/**
 * Iterate through each component within a form.
 *
 * @param {Object} components
 *   The components to iterate.
 * @param {Function} fn
 *   The iteration function to invoke for each component.
 * @param {Boolean} includeAll
 *   Whether or not to include layout components.
 * @param {String} path
 *   The current data path of the element. Example: data.user.firstName
 * @param {Object} parent
 *   The parent object.
 */
function eachComponent(components, fn, includeAll, path, parent) {
    if (!components)
        return;
    path = path || "";
    components.forEach((component) => {
        if (!component) {
            return;
        }
        const hasColumns = component.columns && Array.isArray(component.columns);
        const hasRows = component.rows && Array.isArray(component.rows);
        const hasComps = component.components && Array.isArray(component.components);
        let noRecurse = false;
        const compPath = component.parentPath || path;
        const newPath = component.key
            ? compPath
                ? `${compPath}.${component.key}`
                : component.key
            : "";
        // Keep track of parent references.
        if (parent) {
            // Ensure we don't create infinite JSON structures.
            component.parent = Object.assign({}, parent);
            delete component.parent.components;
            delete component.parent.componentMap;
            delete component.parent.columns;
            delete component.parent.rows;
        }
        // there's no need to add other layout components here because we expect that those would either have columns, rows or components
        const layoutTypes = ["htmlelement", "content"];
        const isLayoutComponent = hasColumns ||
            hasRows ||
            hasComps ||
            layoutTypes.indexOf(component.type) > -1;
        if (includeAll || component.tree || !isLayoutComponent) {
            noRecurse = fn(component, newPath, components);
        }
        const subPath = () => {
            if (component.key &&
                ![
                    "panel",
                    "table",
                    "well",
                    "columns",
                    "fieldset",
                    "tabs",
                    "form",
                ].includes(component.type) &&
                ([
                    "datagrid",
                    "container",
                    "editgrid",
                    "address",
                    "dynamicWizard",
                ].includes(component.type) ||
                    component.tree)) {
                return newPath;
            }
            else if (component.key && component.type === "form") {
                return `${newPath}.data`;
            }
            return compPath;
        };
        if (!noRecurse) {
            if (hasColumns) {
                component.columns.forEach((column) => eachComponent(column.components, fn, includeAll, subPath(), parent ? component : null));
            }
            else if (hasRows) {
                component.rows.forEach((row) => {
                    if (Array.isArray(row)) {
                        row.forEach((column) => eachComponent(column.components, fn, includeAll, subPath(), parent ? component : null));
                    }
                });
            }
            else if (hasComps) {
                eachComponent(component.components, fn, includeAll, subPath(), parent ? component : null);
            }
        }
    });
}
exports.eachComponent = eachComponent;
// Async each component.
function eachComponentAsync(components, fn, includeAll = false, path = "") {
    var _a, _b;
    return __awaiter(this, void 0, void 0, function* () {
        if (!components)
            return;
        for (let i = 0; i < components.length; i++) {
            if (!components[i]) {
                continue;
            }
            let component = components[i];
            const hasColumns = component.columns && Array.isArray(component.columns);
            const hasRows = component.rows && Array.isArray(component.rows);
            const hasComps = component.components && Array.isArray(component.components);
            const compPath = component.parentPath || path;
            const newPath = component.key
                ? compPath
                    ? `${compPath}.${component.key}`
                    : component.key
                : compPath;
            const layoutTypes = ["htmlelement", "content"];
            const isLayoutComponent = hasColumns ||
                hasRows ||
                hasComps ||
                layoutTypes.indexOf(component.type) > -1;
            if (includeAll || component.tree || !isLayoutComponent) {
                if (yield fn(component, components, newPath)) {
                    continue;
                }
            }
            if (hasColumns) {
                for (let j = 0; j < component.columns.length; j++) {
                    yield eachComponentAsync((_a = component.columns[j]) === null || _a === void 0 ? void 0 : _a.components, fn, includeAll, compPath);
                }
            }
            else if (hasRows) {
                for (let j = 0; j < component.rows.length; j++) {
                    let row = component.rows[j];
                    if (Array.isArray(row)) {
                        for (let k = 0; k < row.length; k++) {
                            yield eachComponentAsync((_b = row[k]) === null || _b === void 0 ? void 0 : _b.components, fn, includeAll, compPath);
                        }
                    }
                }
            }
            else if (hasComps) {
                const subPath = isLayoutComponent
                    ? compPath
                    : component.type === "form"
                        ? `${newPath}.data`
                        : newPath;
                yield eachComponentAsync(component.components, fn, includeAll, subPath);
            }
        }
    });
}
exports.eachComponentAsync = eachComponentAsync;
