"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateMinimumWordsSync = exports.validateMinimumWords = void 0;
const FieldError_1 = require("../../../error/FieldError");
const isValidatableTextFieldComponent = (component) => {
    var _a;
    return component && ((_a = component.validate) === null || _a === void 0 ? void 0 : _a.hasOwnProperty('minWords'));
};
const validateMinimumWords = (context) => __awaiter(void 0, void 0, void 0, function* () {
    return (0, exports.validateMinimumWordsSync)(context);
});
exports.validateMinimumWords = validateMinimumWords;
const validateMinimumWordsSync = (context) => {
    var _a, _b;
    const { component, value } = context;
    if (!isValidatableTextFieldComponent(component)) {
        return null;
    }
    const minWords = typeof ((_a = component.validate) === null || _a === void 0 ? void 0 : _a.minWords) === 'string'
        ? parseInt(component.validate.minWords, 10)
        : (_b = component.validate) === null || _b === void 0 ? void 0 : _b.minWords;
    if (minWords && value && typeof value === 'string') {
        if (value.trim().split(/\s+/).length < minWords) {
            const error = new FieldError_1.FieldError('minWords', Object.assign(Object.assign({}, context), { length: String(minWords) }));
            return error;
        }
    }
    return null;
};
exports.validateMinimumWordsSync = validateMinimumWordsSync;
