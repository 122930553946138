"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    'border-default': '',
    'formio-tab-panel-active': 'active',
    'formio-tab-link-active': 'active',
    'formio-tab-link-container-active': 'active',
    'formio-form-error': 'formio-error-wrapper has-message',
    'formio-form-alert': 'alert alert-danger',
    'formio-label-error': '',
    'formio-input-error': '',
    'formio-alert-danger': 'alert alert-danger',
    'formio-alert-success': 'alert alert-success',
    'formio-alert-warning': 'alert alert-warning',
    'formio-modal-cancel-button': 'btn btn-danger formio-dialog-button',
    'formio-modal-confirm-button': 'btn btn-primary formio-dialog-button',
    'form-group': 'formio-form-group',
};
