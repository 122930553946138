"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateUnique = void 0;
const FieldError_1 = require("../../../error/FieldError");
const util_1 = require("../util");
const error_1 = require("../../../error");
const validateUnique = (context) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const { component, value, config } = context;
    if (!component.unique) {
        return null;
    }
    if (!value || (0, util_1.isEmptyObject)(value)) {
        return null;
    }
    if (!config) {
        throw new error_1.ValidatorError("Can't test for unique value without a database config object");
    }
    const isUnique = yield ((_a = config.database) === null || _a === void 0 ? void 0 : _a.isUnique(value));
    return isUnique
        ? null
        : new FieldError_1.FieldError('unique', context);
});
exports.validateUnique = validateUnique;
