"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateProcessSync = exports.validateProcess = void 0;
const lodash_1 = require("lodash");
const rules_1 = require("./rules");
const util_1 = require("./util");
const error_1 = require("../../utils/error");
const validateProcess = (context) => __awaiter(void 0, void 0, void 0, function* () {
    const { component, data, path, instance, scope } = context;
    if (component.multiple) {
        const contextualData = (0, lodash_1.get)(data, path);
        if (contextualData.length > 0) {
            for (let i = 0; i < contextualData.length; i++) {
                const amendedPath = `${path}[${i}]`;
                let value = (0, lodash_1.get)(data, amendedPath);
                if ((instance === null || instance === void 0 ? void 0 : instance.shouldSkipValidation(data)) || (0, util_1.shouldSkipValidation)(component, data)) {
                    return;
                }
                if (component.truncateMultipleSpaces && value && typeof value === 'string') {
                    value = value.trim().replace(/\s{2,}/g, ' ');
                }
                for (const rule of rules_1.rules) {
                    const error = yield rule(Object.assign(Object.assign({}, context), { value, index: i, path: amendedPath }));
                    if (error) {
                        scope.errors.push(error);
                    }
                }
            }
            return;
        }
    }
    let value = (0, lodash_1.get)(data, path);
    if ((instance === null || instance === void 0 ? void 0 : instance.shouldSkipValidation(data)) || (0, util_1.shouldSkipValidation)(component, data)) {
        return;
    }
    if (component.truncateMultipleSpaces && value && typeof value === 'string') {
        value = value.trim().replace(/\s{2,}/g, ' ');
    }
    for (const rule of rules_1.rules) {
        try {
            const error = yield rule(Object.assign(Object.assign({}, context), { value }));
            if (error) {
                scope.errors.push(error);
            }
        }
        catch (err) {
            console.error("Validator error:", (0, error_1.getErrorMessage)(err));
        }
    }
    return;
});
exports.validateProcess = validateProcess;
const validateProcessSync = (context) => {
    const { component, data, path, instance, scope } = context;
    if (component.multiple) {
        const contextualData = (0, lodash_1.get)(data, path);
        if ((contextualData === null || contextualData === void 0 ? void 0 : contextualData.length) > 0) {
            for (let i = 0; i < contextualData.length; i++) {
                const amendedPath = `${path}[${i}]`;
                let value = (0, lodash_1.get)(data, amendedPath);
                if ((instance === null || instance === void 0 ? void 0 : instance.shouldSkipValidation(data)) || (0, util_1.shouldSkipValidation)(component, data)) {
                    return;
                }
                if (component.truncateMultipleSpaces && value && typeof value === 'string') {
                    value = value.trim().replace(/\s{2,}/g, ' ');
                }
                for (const rule of rules_1.rulesSync) {
                    const error = rule(Object.assign(Object.assign({}, context), { value, index: i, path: amendedPath }));
                    if (error) {
                        scope.errors.push(error);
                    }
                }
            }
            return;
        }
    }
    let value = (0, lodash_1.get)(data, path);
    if ((instance === null || instance === void 0 ? void 0 : instance.shouldSkipValidation(data)) || (0, util_1.shouldSkipValidation)(component, data)) {
        return;
    }
    if (component.truncateMultipleSpaces && value && typeof value === 'string') {
        value = value.trim().replace(/\s{2,}/g, ' ');
    }
    for (const rule of rules_1.rulesSync) {
        try {
            const error = rule(Object.assign(Object.assign({}, context), { value }));
            if (error) {
                scope.errors.push(error);
            }
        }
        catch (err) {
            console.error("Validator error:", (0, error_1.getErrorMessage)(err));
        }
    }
    return;
};
exports.validateProcessSync = validateProcessSync;
