"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = [
    {
        key: 'validate.minLength',
        ignore: true
    },
    {
        key: 'validate.maxLength',
        ignore: true
    },
    {
        key: 'validate.pattern',
        ignore: true
    },
    {
        key: 'validate.minWords',
        ignore: true
    },
    {
        key: 'validate.maxWords',
        ignore: true
    }
];
