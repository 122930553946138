"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateRequiredSync = exports.validateRequired = void 0;
const error_1 = require("../../../error");
const util_1 = require("../util");
const isAddressComponentDataObject = (value) => {
    return value !== null && typeof value === 'object' && value.mode && value.address && typeof value.address === 'object';
};
const validateRequired = (context) => __awaiter(void 0, void 0, void 0, function* () {
    return (0, exports.validateRequiredSync)(context);
});
exports.validateRequired = validateRequired;
const validateRequiredSync = (context) => {
    var _a;
    const error = new error_1.FieldError('required', context);
    const { component, value } = context;
    if ((_a = component.validate) === null || _a === void 0 ? void 0 : _a.required) {
        if ((value === null || value === undefined || (0, util_1.isEmptyObject)(value) || !!value === false) &&
            !component.hidden) {
            return error;
        }
        else if (Array.isArray(value) && value.length === 0) {
            return error;
        }
        else if (component.type === 'address' && isAddressComponentDataObject(value)) {
            return (0, util_1.isEmptyObject)(value.address) ? error : Object.values(value.address).every((val) => !!val) ? null : error;
        }
        else if (component.type === 'day' && value === '00/00/0000') {
            return error;
        }
        else if (typeof value === 'object' && value !== null) {
            return Object.values(value).some((val) => !!val) ? null : error;
        }
    }
    return null;
};
exports.validateRequiredSync = validateRequiredSync;
