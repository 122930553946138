"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const utils_1 = require("../../utils");
const jsonLogic_1 = require("./jsonLogic");
class JSONLogicEvaluator extends utils_1.BaseEvaluator {
    static evaluate(func, args = {}, ret = '', tokenize = false, context = {}) {
        let returnVal = null;
        if (typeof func === 'object') {
            try {
                returnVal = jsonLogic_1.jsonLogic.apply(func, args);
            }
            catch (err) {
                returnVal = null;
                console.warn(`An error occured within JSON Logic`, err);
            }
        }
        else {
            returnVal = utils_1.BaseEvaluator.evaluate(func, args, ret, tokenize, context);
        }
        return returnVal;
    }
}
exports.default = {
    evaluator: JSONLogicEvaluator,
    jsonLogic: jsonLogic_1.jsonLogic
};
