"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = [
    {
        key: 'unique',
        ignore: true
    },
    {
        key: 'validate.minWords',
        ignore: true
    },
    {
        key: 'validate.maxWords',
        ignore: true
    },
];
