"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateMultipleSync = exports.validateMultiple = void 0;
const lodash_1 = require("lodash");
const error_1 = require("../../../error");
const isEligible = (component) => {
    // TODO: would be nice if this was type safe
    switch (component.type) {
        case 'hidden':
        case 'select':
            return false;
        case 'address':
            if (!component.multiple) {
                return false;
            }
            break;
        case 'textArea':
            if (!component.as || component.as !== 'json') {
                return false;
            }
            break;
        default:
            return true;
    }
};
const emptyValueIsArray = (component) => {
    // TODO: yikes, this could be better
    switch (component.type) {
        case 'datagrid':
        case 'editgrid':
        case 'file':
            return true;
        case 'select':
            return !!component.multiple;
        case 'tags':
            return component.storeas !== 'string';
        default:
            return false;
    }
};
const validateMultiple = (context) => __awaiter(void 0, void 0, void 0, function* () {
    return (0, exports.validateMultipleSync)(context);
});
exports.validateMultiple = validateMultiple;
const validateMultipleSync = (context) => {
    var _a;
    const { component, value } = context;
    // Skip multiple validation if the component tells us to
    if (!isEligible(component)) {
        return null;
    }
    const shouldBeArray = !!component.multiple;
    const canBeArray = emptyValueIsArray(component);
    const isArray = Array.isArray(value);
    const isRequired = !!((_a = component.validate) === null || _a === void 0 ? void 0 : _a.required);
    if (shouldBeArray) {
        if (isArray) {
            return isRequired ? value.length > 0 ? null : new error_1.FieldError('array_nonempty', context) : null;
        }
        else {
            // Null/undefined is ok if this value isn't required; anything else should fail
            return (0, lodash_1.isNil)(value) ? isRequired ? new error_1.FieldError('array', context) : null : null;
        }
    }
    else {
        if (!canBeArray && isArray) {
            return new error_1.FieldError('nonarray', context);
        }
        return null;
    }
};
exports.validateMultipleSync = validateMultipleSync;
