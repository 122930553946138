"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateMaximumWordsSync = exports.validateMaximumWords = void 0;
const error_1 = require("../../../error");
const isValidatableTextFieldComponent = (component) => {
    var _a;
    return component && ((_a = component.validate) === null || _a === void 0 ? void 0 : _a.hasOwnProperty('maxWords'));
};
const validateMaximumWords = (context) => __awaiter(void 0, void 0, void 0, function* () {
    return (0, exports.validateMaximumWordsSync)(context);
});
exports.validateMaximumWords = validateMaximumWords;
const validateMaximumWordsSync = (context) => {
    var _a, _b;
    const { component, value } = context;
    if (!isValidatableTextFieldComponent(component)) {
        return null;
    }
    const maxWords = typeof ((_a = component.validate) === null || _a === void 0 ? void 0 : _a.maxWords) === 'string'
        ? parseInt(component.validate.maxWords, 10)
        : (_b = component.validate) === null || _b === void 0 ? void 0 : _b.maxWords;
    if (maxWords && typeof value === 'string') {
        if (value.trim().split(/\s+/).length > maxWords) {
            const error = new error_1.FieldError('maxWords', Object.assign(Object.assign({}, context), { length: String(maxWords) }));
            return error;
        }
    }
    return null;
};
exports.validateMaximumWordsSync = validateMaximumWordsSync;
