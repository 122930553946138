"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateMaskSync = exports.validateMask = exports.matchInputMask = void 0;
const lodash_1 = require("lodash");
const error_1 = require("../../../error");
const isMaskType = (obj) => {
    return ((obj === null || obj === void 0 ? void 0 : obj.maskName) &&
        typeof (obj === null || obj === void 0 ? void 0 : obj.maskName) === 'string' &&
        (obj === null || obj === void 0 ? void 0 : obj.value) &&
        typeof (obj === null || obj === void 0 ? void 0 : obj.value) === 'string');
};
const isValidatableComponent = (component) => {
    // For some reason we skip mask validation for time components
    return ((component && component.type && component.type !== 'time') &&
        (component && component.hasOwnProperty('inputMask') && !!component.inputMask) ||
        (component && component.hasOwnProperty('inputMasks') && !(0, lodash_1.isEmpty)(component.inputMasks)));
};
function getMaskByLabel(component, maskName) {
    var _a;
    if (maskName) {
        const inputMask = (_a = component.inputMasks) === null || _a === void 0 ? void 0 : _a.find((inputMask) => {
            return inputMask.label === maskName;
        });
        return inputMask ? inputMask.mask : undefined;
    }
    return;
}
function getInputMask(mask, placeholderChar) {
    if (mask instanceof Array) {
        return mask;
    }
    const maskArray = [];
    for (let i = 0; i < mask.length; i++) {
        switch (mask[i]) {
            case '9':
                maskArray.push(/\d/);
                break;
            case 'A':
                maskArray.push(/[a-zA-Z]/);
                break;
            case 'a':
                maskArray.push(/[a-z]/);
                break;
            case '*':
                maskArray.push(/[a-zA-Z0-9]/);
                break;
            // If char which is used inside mask placeholder was used in the mask, replace it with space to prevent errors
            case placeholderChar:
                maskArray.push(' ');
                break;
            default:
                maskArray.push(mask[i]);
                break;
        }
    }
    return maskArray;
}
function matchInputMask(value, inputMask) {
    if (!inputMask) {
        return true;
    }
    // If value is longer than mask, it isn't valid.
    if (value.length > inputMask.length) {
        return false;
    }
    for (let i = 0; i < inputMask.length; i++) {
        const char = value[i];
        const charPart = inputMask[i];
        if (charPart instanceof RegExp) {
            if (!charPart.test(char)) {
                return false;
            }
            continue;
        }
        else if (charPart !== char) {
            return false;
        }
    }
    return true;
}
exports.matchInputMask = matchInputMask;
const validateMask = (context) => __awaiter(void 0, void 0, void 0, function* () {
    return (0, exports.validateMaskSync)(context);
});
exports.validateMask = validateMask;
// TODO: this function has side effects
const validateMaskSync = (context) => {
    var _a;
    const { component, value } = context;
    if (!isValidatableComponent(component) || !value) {
        return null;
    }
    let inputMask;
    let maskValue;
    if (component.allowMultipleMasks && ((_a = component.inputMasks) === null || _a === void 0 ? void 0 : _a.length)) {
        const mask = value && isMaskType(value) ? value : undefined;
        const formioInputMask = getMaskByLabel(component, mask === null || mask === void 0 ? void 0 : mask.maskName);
        if (formioInputMask) {
            inputMask = getInputMask(formioInputMask);
        }
        maskValue = mask === null || mask === void 0 ? void 0 : mask.value;
    }
    else {
        inputMask = getInputMask(component.inputMask || '');
    }
    if (value != null && inputMask) {
        const error = new error_1.FieldError('mask', context);
        return matchInputMask(maskValue || value, inputMask) ? null : error;
    }
    return null;
};
exports.validateMaskSync = validateMaskSync;
