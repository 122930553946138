"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = [
    {
        key: 'labelPosition',
        ignore: true
    },
    {
        key: 'placeholder',
        ignore: true
    },
    {
        key: 'description',
        ignore: true
    },
    {
        key: 'tooltip',
        ignore: true
    },
    {
        key: 'hideLabel',
        ignore: true
    },
    {
        key: 'autofocus',
        ignore: true
    },
    {
        key: 'tabindex',
        ignore: true
    },
    {
        key: 'hidden',
        ignore: true
    },
    {
        key: 'tableView',
        ignore: true
    },
    {
        key: 'disabled',
        ignore: true
    },
];
