"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateMaximumValueSync = exports.validateMaximumValue = void 0;
const error_1 = require("../../../error");
const isValidatableNumberComponent = (component) => {
    var _a;
    return component && ((_a = component.validate) === null || _a === void 0 ? void 0 : _a.hasOwnProperty('max'));
};
const validateMaximumValue = (context) => __awaiter(void 0, void 0, void 0, function* () {
    return (0, exports.validateMaximumValueSync)(context);
});
exports.validateMaximumValue = validateMaximumValue;
const validateMaximumValueSync = (context) => {
    var _a, _b;
    const { component, value } = context;
    if (!isValidatableNumberComponent(component)) {
        return null;
    }
    const max = typeof ((_a = component.validate) === null || _a === void 0 ? void 0 : _a.max) === 'string'
        ? parseFloat(component.validate.max)
        : (_b = component.validate) === null || _b === void 0 ? void 0 : _b.max;
    if (value == null || !max) {
        return null;
    }
    const parsedValue = typeof value === 'string' ? parseFloat(value) : Number(value);
    if (Number.isNaN(max)) {
        throw new error_1.ValidatorError(`Cannot evaluate maximum value ${max} because it is invalid`);
    }
    if (Number.isNaN(parsedValue)) {
        throw new error_1.ValidatorError(`Cannot validate value ${parsedValue} because it is invalid`);
    }
    return parsedValue <= max
        ? null
        : new error_1.FieldError('max', Object.assign(Object.assign({}, context), { max: String(max) }));
};
exports.validateMaximumValueSync = validateMaximumValueSync;
