"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateMaximumDaySync = exports.validateMaximumDay = void 0;
const error_1 = require("../../../error");
const date_1 = require("../../../utils/date");
const isValidatableDayComponent = (component) => {
    return component && component.type === 'day' && component.hasOwnProperty('maxDate');
};
const validateMaximumDay = (context) => __awaiter(void 0, void 0, void 0, function* () {
    return (0, exports.validateMaximumDaySync)(context);
});
exports.validateMaximumDay = validateMaximumDay;
const validateMaximumDaySync = (context) => {
    const { component, value } = context;
    if (!isValidatableDayComponent(component)) {
        return null;
    }
    if (typeof value !== 'string') {
        throw new error_1.ValidatorError(`Cannot validate day value ${value} because it is not a string`);
    }
    if ((0, date_1.isPartialDay)(component, value)) {
        return null;
    }
    // TODO: this validation probably goes for dates and days
    const format = (0, date_1.getDateValidationFormat)(component);
    const date = (0, date_1.dayjs)(value, format);
    const maxDate = (0, date_1.getDateSetting)(component.maxDate);
    if (maxDate === null) {
        return null;
    }
    else {
        maxDate.setHours(0, 0, 0, 0);
    }
    const error = new error_1.FieldError('maxDay', Object.assign(Object.assign({}, context), { maxDate: String(maxDate) }));
    return date.isBefore((0, date_1.dayjs)(maxDate)) || date.isSame((0, date_1.dayjs)(maxDate)) ? null : error;
};
exports.validateMaximumDaySync = validateMaximumDaySync;
