"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.processSync = exports.process = exports.processOneSync = exports.processOne = exports.validateSync = exports.validate = void 0;
const process_1 = require("./process");
Object.defineProperty(exports, "process", { enumerable: true, get: function () { return process_1.process; } });
Object.defineProperty(exports, "processSync", { enumerable: true, get: function () { return process_1.processSync; } });
const validation_1 = require("./validation");
// Perform a validation on a form asynchonously.
function validate(components, data, instances) {
    return __awaiter(this, void 0, void 0, function* () {
        const scope = yield (0, process_1.process)({
            components,
            data,
            instances,
            scope: { errors: [] },
            processors: [validation_1.validateProcess]
        });
        return scope.errors;
    });
}
exports.validate = validate;
// Perform a validation on a form synchronously.
function validateSync(components, data, instances) {
    return (0, process_1.processSync)({
        components,
        data,
        instances,
        scope: { errors: [] },
        processors: [validation_1.validateProcessSync]
    }).errors;
}
exports.validateSync = validateSync;
__exportStar(require("./validation"), exports);
var processOne_1 = require("./processOne");
Object.defineProperty(exports, "processOne", { enumerable: true, get: function () { return processOne_1.processOne; } });
Object.defineProperty(exports, "processOneSync", { enumerable: true, get: function () { return processOne_1.processOneSync; } });
