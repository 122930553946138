"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.processSync = exports.process = void 0;
const formUtil_1 = require("../utils/formUtil");
const processOne_1 = require("./processOne");
function process(context) {
    return __awaiter(this, void 0, void 0, function* () {
        const { instances, processors, components, data, scope } = context;
        yield (0, formUtil_1.eachComponentDataAsync)(components, data, data, (component, data, row, path, components, index) => __awaiter(this, void 0, void 0, function* () {
            yield (0, processOne_1.processOne)({
                component,
                components,
                processors,
                data,
                path,
                row,
                index,
                scope,
                instance: instances ? instances[path] : undefined,
            });
            if (scope.noRecurse) {
                scope.noRecurse = false;
                return true;
            }
        }));
        return scope;
    });
}
exports.process = process;
function processSync(context) {
    const { instances, processors, components, data, row, scope } = context;
    (0, formUtil_1.eachComponentData)(components, data, row || data, (component, data, row, path, components, index) => {
        (0, processOne_1.processOneSync)({
            component,
            components,
            processors,
            data,
            path,
            row,
            index,
            scope,
            instance: instances ? instances[path] : undefined,
        });
        if (scope.noRecurse) {
            scope.noRecurse = false;
            return true;
        }
    });
    return scope;
}
exports.processSync = processSync;
