"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateMaximumSelectedCountSync = exports.validateMaximumSelectedCount = void 0;
const error_1 = require("../../../error");
const isValidatableSelectBoxesComponent = (component) => {
    var _a;
    return component && ((_a = component.validate) === null || _a === void 0 ? void 0 : _a.hasOwnProperty('maxSelectedCount'));
};
function validateValue(value) {
    if (value == null || typeof value !== 'object') {
        throw new error_1.ValidatorError(`Cannot validate maximum selected count for value ${value} as it is not an object`);
    }
    const subValues = Object.values(value);
    if (!subValues.every((value) => typeof value === 'boolean')) {
        throw new error_1.ValidatorError(`Cannot validate maximum selected count for value ${value} because it has non-boolean members`);
    }
}
const validateMaximumSelectedCount = (context) => __awaiter(void 0, void 0, void 0, function* () {
    return (0, exports.validateMaximumSelectedCountSync)(context);
});
exports.validateMaximumSelectedCount = validateMaximumSelectedCount;
const validateMaximumSelectedCountSync = (context) => {
    var _a, _b;
    const { component, value } = context;
    if (!isValidatableSelectBoxesComponent(component)) {
        return null;
    }
    if (!value) {
        return null;
    }
    validateValue(value);
    const max = typeof ((_a = component.validate) === null || _a === void 0 ? void 0 : _a.maxSelectedCount) === 'string'
        ? parseFloat(component.validate.maxSelectedCount)
        : (_b = component.validate) === null || _b === void 0 ? void 0 : _b.maxSelectedCount;
    if (!max) {
        return null;
    }
    const count = Object.keys(value).reduce((sum, key) => (value[key] ? ++sum : sum), 0);
    // Should not be triggered if there is no options selected at all
    if (count <= 0) {
        return null;
    }
    return count > max
        ? new error_1.FieldError(component.maxSelectedCountMessage || 'maxSelectedCount', Object.assign(Object.assign({}, context), { maxCount: String(max) }))
        : null;
};
exports.validateMaximumSelectedCountSync = validateMaximumSelectedCountSync;
