"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = [
    {
        ignore: true,
        key: 'unique',
    },
    {
        weight: 110,
        key: 'validate.minLength',
        label: 'Minimum Length',
        placeholder: 'Minimum Length',
        type: 'textfield',
        tooltip: 'The minimum length requirement this field must meet.',
        input: true
    },
    {
        weight: 120,
        key: 'validate.maxLength',
        label: 'Maximum Length',
        placeholder: 'Maximum Length',
        type: 'textfield',
        tooltip: 'The maximum length requirement this field must meet.',
        input: true
    }
];
