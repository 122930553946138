"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.boolValue = exports.escapeRegExCharacters = void 0;
const lodash_1 = require("lodash");
/**
 * Escapes RegEx characters in provided String value.
 *
 * @param {String} value
 *   String for escaping RegEx characters.
 * @returns {string}
 *   String with escaped RegEx characters.
 */
function escapeRegExCharacters(value) {
    return value.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&');
}
exports.escapeRegExCharacters = escapeRegExCharacters;
/**
 * Determines the boolean value of a setting.
 *
 * @param value
 * @return {boolean}
 */
function boolValue(value) {
    if ((0, lodash_1.isBoolean)(value)) {
        return value;
    }
    else if ((0, lodash_1.isString)(value)) {
        return (value.toLowerCase() === 'true');
    }
    else {
        return !!value;
    }
}
exports.boolValue = boolValue;
